import { UserProtectedRoute } from "./UserProtectedRoute";
import ProfileForm from '../pages/quiz/Quiz';
import Welcome from '../pages/quiz/Welcome';
import { IndexLayout } from '../components/layout/IndexLayout'


// Маршруты для кабинета
const userRoutes = [
  {
    path: "/",
    element: <UserProtectedRoute />, // Оборачиваем компонент в ProtectedRoute если токена нет мы просто игнорируем 
    //всё что ниже и переходим в login/
    children: [

      {
        path: "/quiz",
        element: <ProfileForm />,
      },

      {
        path: "/welcome",
        element: <Welcome />,
      },






      // my_cabinet ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      {
        path: "/my_cabinet",
        element: <IndexLayout />,
        children: [




          // курсы ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

          {
            path: ":url_course/",
            lazy: async () => {
              let { Course } = await import("../pages/cabinet/Course")
              return { Component: Course }
            }
          },










          // курс -> урок ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

          {
            path: "curs/:url_course/:url_folder/:url_lesson",
            lazy: async () => {
              let { Folder } = await import("../pages/cabinet/Folder")
              return { Component: Folder }
            }
          },

          // клуб -> урок ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

          {
            path: ":url_club/:url_course/:url_folder/:url_lesson",
            lazy: async () => {
              let { Folder } = await import("../pages/cabinet/Folder")
              return { Component: Folder }
            }
          },








          // клуб -> уроки ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

          {
            path: ":url_club/:url_course/:url_folder/",
            lazy: async () => {
              let { LessonsList } = await import("../pages/cabinet/lessons/lessons_list")
              return { Component: LessonsList }
            }
          },

          // клуб -> уроки ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

          {
            path: ":url_course/:url_folder/",
            lazy: async () => {
              let { LessonsList } = await import("../pages/cabinet/lessons/lessons_list")
              return { Component: LessonsList }
            }
          },








          // Начальный урок  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

          {
            path: "introductory_lesson",
            lazy: async () => {
              let { IntroductoryLesson } = await import("../pages/cabinet/IntroductoryLesson")
              return { Component: IntroductoryLesson }
            }
          },


          // Мой кабинет  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

          {
            path: "",
            lazy: async () => {
              let { MyCabinet } = await import("../pages/cabinet/my_cabinet/MyCabinet")
              return { Component: MyCabinet }
            }
          },

          // Удалить  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          {
            path: "users",
            lazy: async () => {
              let { Users } = await import("../pages/admin/users/Users")
              return { Component: Users }
            }
          }

        ]

      },
    ],
  },
];


export default userRoutes 
