// Проверяет есть ли токен?
// Старый он или нет? Если старый то обновляет
// Прописывает ключ в заголовки
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

import axios from "axios";
import { jwtDecode } from "jwt-decode";





// Создание Axios instance с базовой конфигурацией
const createTokenManagerMiddleware = (saveTokens, logout) => {


  const TokenManagerMiddleware = axios.create({
    // удалил так на сервере выскакивала ошибка CORS
    // baseURL: "http://localhost:8000/",
  });


  //Основная функция ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // Middleware для добавления токенов в заголовки запросов
  TokenManagerMiddleware.interceptors.request.use(
    async (config) => {
      console.log('--Запустилась Middleware');

      if (!config.headers.Authorization) {
        console.log('--нет заголовка');
        const accessToken = localStorage.getItem("accessToken");

        // если есть accessToken и он просрочен
        if (accessToken && isTokenExpired(accessToken)) {

          // Попытка обновить access токен с помощью refresh токена
          const newAccessToken = await refreshAccessToken(saveTokens);
          if (newAccessToken) {
            config.headers.Authorization = `Bearer ${newAccessToken}`;
          }

        } else if (accessToken) {
          // accessToken свежий/прописываем заголовок 
          config.headers.Authorization = `Bearer ${accessToken}`;
          console.log('--access токен не просрочен');
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Вспомогательные функции
  const isTokenExpired = (token) => {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp < Date.now() / 1000;
  };


  // Обновление access токена
  const refreshAccessToken = async () => {
    try {
      console.log('-Запустилась refresh-функция');
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await axios.post("/api/token/refresh/", { 'refresh': refreshToken });

      const { access: newAccessToken, refresh: newRefreshToken } = response.data;

      // Используем функцию saveTokens, переданную в контекст
      await saveTokens({ access: newAccessToken, refresh: newRefreshToken });

      return newAccessToken;

    } catch (error) {
      console.error("refreshToken устарел, выход из системы", error);
      logout()

      window.location.href = '/login'; //переход на страницу без перезагрузки

      throw new Error("TokenExpired"); // это делал для передачи ошибки выше
    }
  };

  return TokenManagerMiddleware;
};

export default createTokenManagerMiddleware;
