import { useState, useContext } from "react"
import { AuthContext } from '../../context/authContext' // Контекст
import './style.scss';

// 4 квиза
import UserNameQuiz from './UserNameQuiz'
import CountryQuiz from './CountryQuiz'
import PhoneQuiz from './PhoneQuiz'
import BirthDateQuiz from './BirthDateQuiz'


// router-dom
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';





const ProfileForm = () => {

  // пока не нужно (ссылки)
  const location = useLocation();

  // контекст 
  const { profile, TokenManagerMiddleware } = useContext(AuthContext)




  // у кнопки изначально отключена настройка выключения кнопки 
  const [isDisabled, setIsDisabled] = useState(false);




  // ~~~~~~ Проверяем, сортируем квизы ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // index квиза (порядковый номер) 
  const [currentIndex, setCurrentIndex] = useState(0);

  // правильная сортировка квизов
  const desiredOrder = ['username', 'country', 'phone', 'birth_date'];


  // Проверяем на существование объектов(username, phone ...) в profile
  const unusedProfileFields = Object.entries(profile)
    .filter(([key, value]) => value === null || value === '')
    // Сортируем по желаемому порядку
    .sort(
      (a, b) => desiredOrder.indexOf(a[0]) - desiredOrder.indexOf(b[0])
    );




  // ~~~~~~~ Все квизы ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // тут у нас все квизы которые есть будут 
  // сравниваться с пустыми полями profile  
  const renderFormField = (fieldName) => {
    switch (fieldName) {
      case 'username':
        return (

          <UserNameQuiz
            handleChange={handleChange}
            isDisabled={isDisabled}
          />

        );

      case 'country':
        return (

          <CountryQuiz
            handleChange={handleChange}
            isDisabled={isDisabled}
          />

        );
      case 'phone':
        return (

          <PhoneQuiz
            handleChange={handleChange}
            isDisabled={isDisabled}
          />

        );
      case 'birth_date':
        return (

          <BirthDateQuiz
            handleChange={handleChange}
            isDisabled={isDisabled}
          />

        );
      default:
        return null;
    }
  };






  // ~~~ Индекс ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


  // прибавляем к индексу единицу (переходим на следующий квиз)
  const handleNext = () => {
    if (currentIndex < unusedProfileFields.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };






  // ~~~ Отправка в Django ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


  // отправляем в django и выключаем квиз если он последний
  async function onSubmit(con) {

    try {
      const otvet = await TokenManagerMiddleware.post(`/api/quiz/`, con, { headers: { 'content-type': 'multipart/form-data' } })


      //Переход в quiz (c перезагрузкой) если это последние квиз
      if (currentIndex === unusedProfileFields.length - 1) {
        window.location.href = '/my_cabinet';
      }

      setIsDisabled(false); // включить кнопку 


    } catch (e) {
      if (e.response.status === 401) {
        console.log('Error 401, ')
      }
    };

  }





  // ~~~~ Функция сохранения отправки и перехода ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // Сохраняем данные из формы и переходим на следующий квиз
  function handleChange(data) {

    let d, y;

    // Перебираем ключи в объекте
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        d = key;          // Ключ
        y = data[key];    // Значение
      }
    }


    const updatedContent = { [d]: y }

    setIsDisabled(true); // выключить кнопку 
    onSubmit(updatedContent) // отправка данных на сервер

    handleNext(); // переходим к следующему квизу

    return updatedContent; // возвращаем обновленное состояние
  }




  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


  return (
    <div className='bg_quiz'>
      <div className='wrapper_quiz'>


        {/* вставляем только те блоки которые пустые у пользователя */}
        {unusedProfileFields.length > 0 ? (
          <div className='quiz'>
            {renderFormField(unusedProfileFields[currentIndex][0])}
          </div>
        ) : (
          <Navigate to="/my_cabinet" />
        )}



      </div>
    </div >


  );
};

export default ProfileForm;

